<template>
  <div>
    <sensitiveXq></sensitiveXq>
  </div>
</template>

<script>
import sensitiveXq from "../../../components/sensitiveXq"
export default {
  components:{
    sensitiveXq
  }
}
</script>

<style>

</style>